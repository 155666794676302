//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { emptyDataIllustration } from "@debionetwork/ui-icons";
export default {
  name: "EmptyDataCard",
  data: () => ({
    emptyDataIllustration
  }),
  methods: {
    goToAddPage() {
      this.$router.push({
        name: "customer-add-genetic-data"
      });
    }
  }
};