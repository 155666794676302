import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapState } from "vuex";
import { queryGeneticDataByOwnerId } from "@debionetwork/polkadot-provider";
import EmptyDataCard from "./EmptyDataCard";
export default {
  name: "RequestAnalysis",
  data: () => ({
    stepperItems: [{
      number: 1,
      title: "Select Genetic Data",
      active: true
    }, {
      number: 2,
      title: "Select Service & Analyst",
      active: false
    }, {
      number: 3,
      title: "Checkout and Payment",
      active: false
    }, {
      number: 4,
      title: "Summary/Instruction",
      active: false
    }],
    isEmpty: false,
    headers: [{
      text: "Title",
      value: "title",
      sortable: true
    }, {
      text: "Description",
      value: "description",
      sortable: false
    }, {
      text: "Uploaded",
      value: "uploadDate",
      sortable: true
    }],
    items: []
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet
    })
  },
  async mounted() {
    await this.fetchGeneticData();
  },
  components: {
    EmptyDataCard
  },
  methods: {
    ...mapMutations({
      setSelectedGeneticData: "geneticData/SET_SELECTED_DATA"
    }),
    async fetchGeneticData() {
      this.items = [];
      const accountId = this.wallet.address;
      let dataList;
      try {
        dataList = await queryGeneticDataByOwnerId(this.api, accountId);
        for (const {
          id,
          owenerId,
          reportLink,
          title,
          description,
          createdAt,
          updatedAt
        } of dataList) {
          let uploadAt;
          if (updatedAt !== "0") {
            uploadAt = this.formatDate(updatedAt);
          } else {
            uploadAt = this.formatDate(createdAt);
          }
          const item = {
            id,
            owenerId,
            reportLink,
            title,
            description,
            uploadAt
          };
          this.items.push(item);
        }
        this.items.sort((a, b) => {
          if (new Date(a.uploadAt) < new Date(b.uploadAt)) {
            return 1;
          }
          if (new Date(a.uploadAt) > new Date(b.uploadAt)) {
            return -1;
          }
          return 0;
        });
      } catch (error) {
        this.isEmpty = true;
      }
    },
    selectData(item) {
      this.setSelectedGeneticData(item);
      this.$router.push({
        name: "customer-request-analysis-service"
      });
    },
    handleBack() {
      this.$router.push({
        name: "customer-genetic-data"
      });
    },
    formatDate(date) {
      const formattedDate = new Date(parseInt(date.replace(/,/g, ""))).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric"
      });
      return formattedDate;
    }
  }
};